/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'web-site': {
    width: 552,
    height: 552,
    viewBox: '0 -24 414 414',
    data: '<path pid="0" d="M367 365.305H47c-25.914 0-47-21.086-47-47V47C0 21.086 21.086 0 47 0h320c25.914 0 47 21.086 47 47v271.305c0 25.914-21.086 47-47 47zM47 14c-18.195 0-33 14.805-33 33v271.305c0 18.195 14.805 33 33 33h320c18.195 0 33-14.805 33-33V47c0-18.195-14.805-33-33-33zm0 0"/><path pid="1" d="M350.82 258.434H240.824a7.001 7.001 0 010-14H350.82c3.864 0 7 3.136 7 7 0 3.867-3.132 7-7 7zm0 0M350.82 309.043H240.824a7.001 7.001 0 010-14H350.82c3.864 0 7 3.137 7 7 0 3.867-3.132 7-7 7zm0 0M350.82 157.215H240.824a7.001 7.001 0 010-14H350.82c3.864 0 7 3.137 7 7 0 3.867-3.132 7-7 7zm0 0M350.82 207.824H240.824a7.001 7.001 0 010-14H350.82c3.864 0 7 3.137 7 7 0 3.867-3.132 7-7 7zm0 0M407 93.73H7c-3.867 0-7-3.132-7-7 0-3.867 3.133-7 7-7h400c3.867 0 7 3.133 7 7 0 3.868-3.133 7-7 7zm0 0M100.914 68.781c-12.012 0-21.785-9.77-21.785-21.781s9.773-21.781 21.785-21.781 21.781 9.77 21.781 21.781-9.77 21.781-21.78 21.781zm0-29.562c-4.293 0-7.785 3.492-7.785 7.781s3.492 7.781 7.785 7.781c4.29 0 7.781-3.492 7.781-7.781s-3.492-7.781-7.78-7.781zm0 0M50.477 68.781c-12.008 0-21.782-9.77-21.782-21.781s9.774-21.781 21.782-21.781c12.011 0 21.785 9.77 21.785 21.781s-9.774 21.781-21.785 21.781zm0-29.562c-4.29 0-7.782 3.492-7.782 7.781s3.493 7.781 7.782 7.781c4.293 0 7.785-3.492 7.785-7.781s-3.492-7.781-7.785-7.781zm0 0M151.348 68.781c-12.012 0-21.782-9.77-21.782-21.781s9.77-21.781 21.782-21.781c12.011 0 21.785 9.77 21.785 21.781s-9.774 21.781-21.785 21.781zm0-29.562c-4.293 0-7.782 3.492-7.782 7.781s3.493 7.781 7.782 7.781c4.293 0 7.785-3.492 7.785-7.781s-3.492-7.781-7.785-7.781zm0 0M188 309.043H61c-3.867 0-7-3.133-7-7V150.215c0-3.863 3.133-7 7-7h127c3.867 0 7 3.137 7 7v151.828c0 3.867-3.133 7-7 7zm-120-14h113V157.215H68zm0 0"/>'
  }
})
