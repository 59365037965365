import {
	create,
	get,
	list,
	lookup,
	Lookup,
	APIListResponse,
	APIPaginatedResponse,
	APIResponse,
	update,
} from "./base"

interface Advertiser {
	name: string
	teams: number[]
	id: number | null
	pricing_model: number | Lookup | null
	agency?: number | Lookup
	display_brand_safe: boolean | null
	video_brand_safe: boolean | null
}

export interface AdvertiserData extends Advertiser {
	id: number
	agency?: Lookup
	pricing_model: Lookup
}

export const defaultAdvertiserData: Advertiser = {
	id: null,
	name: "",
	teams: [],
	pricing_model: null,
	display_brand_safe: null,
	video_brand_safe: null,
}

export const getAdvertiser: APIResponse<AdvertiserData> = get("advertisers")
export const getAdvertisers: APIPaginatedResponse<AdvertiserData> = list(
	"advertisers"
)
export const lookupAdvertisers = lookup("advertisers")
export const createAdvertiser = create("advertisers")
export const updateAdvertiser = update("advertisers")
