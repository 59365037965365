import Vue, { DirectiveOptions } from "vue"
import Component from "vue-class-component"

import "normalize.css"
import ElementUI from "element-ui"
import LoadScript from "vue-plugin-load-script"
import locale from "element-ui/lib/locale/lang/en"
import SvgIcon from "vue-svgicon"

import "@/styles/element-variables.scss"
import "@/styles/index.scss"

import App from "@/App.vue"
import store from "@/store"
import router from "@/router/index"
import "@/icons/components"
import "@/permission"
import * as directives from "@/directives"
import * as filters from "@/filters"
import Icon from "vue-awesome/components/Icon.vue"
import VueCompositionApi from "@vue/composition-api"
// Fixes '$ref is not defined' errors in VSCode
import { _$ref } from "unplugin-vue2-script-setup/ref-macros"

Vue.use(ElementUI, { locale })
Vue.use(LoadScript)
Vue.use(VueCompositionApi)

Vue.component("v-icon", Icon)

Vue.use(SvgIcon, {
	tagName: "svg-icon",
	defaultWidth: "1em",
	defaultHeight: "1em",
})
;(Vue as any).loadScript("//imasdk.googleapis.com/js/sdkloader/ima3.js")

Object.keys(directives).forEach((key) => {
	Vue.directive(key, (directives as { [key: string]: DirectiveOptions })[key])
})

Object.keys(filters).forEach((key) => {
	Vue.filter(key, (filters as { [key: string]: Function })[key])
})

Vue.config.warnHandler = function (err, vm, info) {
	if (err === "`createElement()` has been called outside of render function.")
		return
	console.warn(`[Vue warn] ${err}`)
}
Vue.config.productionTip = false

Component.registerHooks(["beforeRouteEnter", "beforeRouteLeave"])

new Vue({
	router,
	store,
	render: (h) => h(App),
}).$mount("#app")
