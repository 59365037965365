import request from "@/utils/request"
import { UserData } from "@/select/api/types"

export const defaultUserData: UserData = {
	id: null,
	first_name: "",
	last_name: "",
	email: "",
	is_staff: false,
	teams: [],
	agency: undefined,
	roles: [],
}

export const getUsers = (params: any) =>
	request({
		url: "/users/",
		method: "get",
		params,
	})

export const getUser = (id: number, params: any) =>
	request({
		url: `/users/${id}/`,
		method: "get",
		params,
	})

export const createUser = (data: any) =>
	request({
		url: "/users/",
		method: "post",
		data,
	})

export const updateUser = (id: number, data: any) =>
	request({
		url: `/users/${id}/`,
		method: "put",
		data,
	})

export const getUserInfo = (data: any) =>
	request({
		url: "/profile",
		method: "get",
		data,
	})

interface LoginData {
	username: string
	password: string
}

export const login = (data: LoginData) =>
	request({
		url: "/auth/jwt/create/",
		method: "post",
		data,
	})

export const logout = () =>
	request({
		url: "/users/logout",
		method: "post",
	})

export const setPassword = (new_password: string, current_password: string) =>
	request({
		url: "/auth/users/set_password/",
		method: "post",
		data: { new_password, current_password },
	})

export const requestPasswordReset = (email: string) =>
	request({
		url: "/auth/users/reset_password/",
		method: "post",
		data: { email },
	})

interface ResetPasswordData {
	uid: string
	token: string
	new_password: string
}

export const resetPassword = (data: ResetPasswordData) =>
	request({
		url: "/auth/users/reset_password_confirm/",
		method: "post",
		data,
	})
