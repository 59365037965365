import { RouteConfig } from "vue-router"
import Layout from "@/layout/index.vue"

const agreementRouter: RouteConfig = {
	path: "/agreements",
	component: Layout,
	meta: {
		title: "Agreements",
		roles: ["agency admin", "admin", "team admin"],
	},
	children: [
		{
			path: "",
			component: () =>
				import(
					/* webpackChunkName: "agreementlist" */ "@/views/agreements/index.vue"
				),
			meta: {
				title: "Agreements",
				breadcrumb: false,
				icon: "agreement",
			},
		},
		{
			path: "create",
			component: () =>
				import(
					/* webpackChunkName: "agreementdetail" */ "@/views/agreements/detail.vue"
				),
			meta: {
				title: "Create Agreement",
				hidden: true,
			},
		},
		{
			path: "edit/:id(\\d+)",
			component: () =>
				import(
					/* webpackChunkName: "agreementdetail" */ "@/views/agreements/detail.vue"
				),
			meta: {
				title: "Edit Agreement",
				hidden: true,
			},
		},
	],
}

export default agreementRouter
