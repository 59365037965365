import {
	apiDelete,
	bulkDelete,
	bulkUpdate,
	create,
	getHistory,
	list,
	post,
	put,
	update,
} from "./base"

const ENDPOINT = "lineitems"
export const getLineItems = list(ENDPOINT)
export const createLineItem = create(ENDPOINT)
export const updateLineItem = update(ENDPOINT)
export const updateLineItems = bulkUpdate(ENDPOINT)
export const deleteLineItem = apiDelete(ENDPOINT)
export const deleteLineItems = bulkDelete(ENDPOINT)
export const getLineItemHistory = getHistory(ENDPOINT)

export const setLineItemsActive = (data: any) =>
	put(`${ENDPOINT}/set_active`, data)
export const syncLineItems = (data: any) => put(`${ENDPOINT}/sync`, data)
