export enum Type {
	Country = 1,
	Subdivision1,
	Subdivision2,
	City,
}

export function typeDisplay(type: Type) {
	if (type === Type.Country) return "Country"
	if (type === Type.Subdivision1) return "Subdivision 1"
	if (type === Type.Subdivision2) return "Subdivision 2"
	if (type === Type.City) return "City"
}
