import { APIPaginatedResponse, create, get, list, lookup, update } from "./base"
import { ZoneData } from "./types"

const ENDPOINT = "zones"
export const getZones: APIPaginatedResponse<ZoneData> = list(ENDPOINT)
export const getZone = get(ENDPOINT)
export const lookupZones = lookup(ENDPOINT)

export const createZone = create(ENDPOINT)
export const updateZone = update(ENDPOINT)
