/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'settings': {
    width: 16,
    height: 16,
    viewBox: '0 0 54 54',
    data: '<path pid="0" d="M53.188 23.518l-3.128-.602a5.406 5.406 0 01-4.035-3.354 5.407 5.407 0 01.677-5.203l1.964-2.679a1 1 0 00-.1-1.298L44.324 6.14a1 1 0 00-1.268-.121l-2.638 1.786a5.396 5.396 0 01-3.033.942 5.423 5.423 0 01-5.393-4.617L31.487.847A.997.997 0 0030.5 0h-6a1 1 0 00-.982.812l-.777 4.041a5.459 5.459 0 01-5.357 4.416 5.39 5.39 0 01-3.033-.941L10.944 6.02a1.001 1.001 0 00-1.268.121l-4.243 4.242c-.349.349-.391.9-.1 1.299l1.964 2.679a5.411 5.411 0 01.677 5.203 5.413 5.413 0 01-4.036 3.353l-3.128.602A.998.998 0 000 24.5v6a1 1 0 00.848.988l3.283.505a5.408 5.408 0 014.157 3.2c.75 1.72.57 3.673-.482 5.226L6.02 43.057a1 1 0 00.121 1.268l4.242 4.242a1 1 0 001.298.1l2.679-1.964a5.38 5.38 0 013.197-1.059 5.46 5.46 0 015.359 4.416l.602 3.129A1 1 0 0024.5 54h6a1 1 0 00.988-.848l.355-2.309c.466-3.032 3.067-4.618 5.396-4.618 1.146 0 2.25.366 3.196 1.06l1.884 1.381a1 1 0 001.298-.1l4.242-4.242a1 1 0 00.121-1.268l-1.786-2.638a5.413 5.413 0 01-.482-5.226 5.41 5.41 0 014.157-3.2l3.283-.505A.998.998 0 0054 30.5v-6a1 1 0 00-.812-.982zM36 27.5c0 4.687-3.813 8.5-8.5 8.5S19 32.187 19 27.5s3.813-8.5 8.5-8.5 8.5 3.813 8.5 8.5z"/><path pid="1" d="M27.5 22c-3.033 0-5.5 2.468-5.5 5.5s2.467 5.5 5.5 5.5 5.5-2.468 5.5-5.5-2.467-5.5-5.5-5.5z"/>'
  }
})
