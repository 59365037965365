/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'euro': {
    width: 16,
    height: 16,
    viewBox: '0 0 481.569 481.569',
    data: '<path pid="0" d="M444.288 429.288c-5.4-13.2-10.9-26.4-16.9-39.4-5.3-11.6-12.1-15-24.8-12.1-16.1 3.7-31.9 8.6-48 11.9-31.1 6.5-62.3 7.1-93-2.6-38.5-12.1-59-40-71.6-76h104.4c8.2 0 14.8-6.6 14.8-14.8v-32.9c0-8.2-6.6-14.8-14.8-14.8h-114.4c0-9.2-.1-18 0-26.8h114.4c8.2 0 14.8-6.6 14.8-14.8v-32.9c0-8.2-6.6-14.8-14.8-14.8h-100c0-.4 0-.8.2-1 12-27.3 29.5-49.2 58.2-60.6 33.4-13.2 67.5-12.9 101.9-5.8 16.3 3.3 32.3 8.3 48.6 12 11.9 2.7 18.8-.8 23.9-11.9 5.9-12.8 11.3-25.8 16.7-38.9 5.1-12.3 2.1-21-9.5-27.8-2.9-1.7-5.9-3.1-9-4.3-48.2-18.8-97.9-25.8-149.2-17.6-36.1 5.8-69.8 18.2-98.9 40.8-36.7 28.4-60.5 65.9-74.3 110l-1.7 5.1h-51.4c-8.2 0-14.8 6.6-14.8 14.8v32.9c0 8.2 6.6 14.8 14.8 14.8h40.5v26.8h-40.5c-8.2 0-14.8 6.6-14.8 14.8v32.9c0 8.2 6.6 14.8 14.8 14.8h48.8c3.7 12 6.8 24.2 11.5 35.7 24.7 59.6 66.1 102 128.4 122.2 51.5 16.7 103.4 16.2 155.3 1.9 13.5-3.7 26.9-8.5 39.7-14.4 12.4-5.7 15.6-15.3 10.7-27.2z"/>'
  }
})
