import { RouteConfig } from "vue-router"
import Layout from "@/layout/index.vue"

const teamRouter: RouteConfig = {
	path: "/teams",
	component: Layout,
	meta: {
		title: "Teams",
		roles: ["agency admin", "admin", "team admin"],
	},
	children: [
		{
			path: "",
			component: () =>
				import(/* webpackChunkName: "teamlist" */ "@/views/teams/index.vue"),
			meta: {
				title: "Teams",
				breadcrumb: false,
				icon: "team",
			},
		},
		{
			path: "create",
			component: () =>
				import(/* webpackChunkName: "teamdetail" */ "@/views/teams/detail.vue"),
			meta: {
				title: "Create Team",
				hidden: true,
			},
		},
		{
			path: "edit/:id(\\d+)",
			component: () =>
				import(/* webpackChunkName: "teamdetail" */ "@/views/teams/detail.vue"),
			meta: {
				title: "Edit Team",
				hidden: true,
			},
		},
	],
}

export default teamRouter
