import { RouteConfig } from "vue-router"
import Layout from "@/layout/index.vue"

const advertiserRouter: RouteConfig = {
	path: "/advertisers",
	component: Layout,
	meta: {
		title: "Advertisers",
		roles: ["agency admin", "admin", "team admin"],
	},
	children: [
		{
			path: "",
			component: () =>
				import(
					/* webpackChunkName: "advertiserlist" */ "@/views/advertisers/index.vue"
				),
			meta: {
				title: "Advertisers",
				breadcrumb: false,
				icon: "tree",
			},
		},
		{
			path: "create",
			component: () =>
				import(
					/* webpackChunkName: "advertiserdetail" */ "@/views/advertisers/detail.vue"
				),
			meta: {
				title: "Create Advertiser",
				hidden: true,
			},
		},
		{
			path: "edit/:id(\\d+)",
			component: () =>
				import(
					/* webpackChunkName: "advertiserdetail" */ "@/views/advertisers/detail.vue"
				),
			meta: {
				title: "Edit Advertiser",
				hidden: true,
			},
		},
	],
}

export default advertiserRouter
