export enum Role {
	CampaignViewer = "campaign viewer",
	CampaignEditor = "campaign editor",
	ReportingUser = "reporting user",
	FinanceUser = "finance user",
	AgencyAdmin = "agency admin",
	PublisherAdmin = "publisher admin",
	BundleEditor = "bundle editor",
	Auditor = "auditor",
	Admin = "admin",
	TeamAdmin = "team admin",
}

const roleMap = new Map([
	[Role.CampaignViewer, "View Campaigns"],
	[Role.CampaignEditor, "Modify Campaigns"],
	[Role.ReportingUser, "Reporting"],
	[Role.FinanceUser, "Finance"],
	[Role.AgencyAdmin, "Agency Admin"],
	[Role.PublisherAdmin, "Publisher Admin"],
	[Role.BundleEditor, "Bundle Editor"],
	[Role.Auditor, "Auditor"],
	[Role.Admin, "Admin"],
	["team admin", "Team Admin"],
])

export function roleDisplay(r: Role) {
	return roleMap.get(r) || `Unknown (${r})`
}
