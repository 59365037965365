import { AxiosPromise } from "axios"
import { parseParams } from "./base"
import { AgreementData, TeamData, UserData } from "./types"
import request from "@/utils/request"
export * from "./advertiser"
export * from "./agency"
export * from "./agreement"
export * from "./agreementprice"
// eslint doesn't recognise this syntax at the moment
// eslint-disable-next-line
export type { Lookup } from "./base"
export * from "./campaign"
export * from "./campaigncreative"
export * from "./audit"
export * from "./context"
export * from "./creative"
export * from "./inventory"
export * from "./lineitem"
export * from "./lineitemcreative"
export * from "./location"
export * from "./pricingmodel"
export * from "./publisher"
export * from "./reporting"
export * from "./team"
export * from "./user"
export * from "./zone"

const getList = (baseUrl: string) => (params: any) =>
	request({
		url: `/${baseUrl}/`,
		method: "get",
		params,
		paramsSerializer: (params) => parseParams(params),
	})

type APIListCall<T> = (params: any) => AxiosPromise<T[]>

export const getAgreements = getList("agreements")
export const getAgreementPrices = getList("agreementprices")
export const getCampaigns = getList("campaigns")
export const getContexts = getList("contexts")
export const getGeos = getList("locations")
export const getPricingModels = getList("pricingmodels")
export const getPublishers = getList("publishers")
export const getTeams = getList("teams")
export const getUsers = getList("users")

const getEntity = (baseUrl: string) => (id: number, params: any) =>
	request({
		url: `/${baseUrl}/${id}/`,
		method: "get",
		params,
		paramsSerializer: (params) => parseParams(params),
	})

const get = (url: string, no_slash = false) => (params: any) =>
	request({
		url: `/${url}${no_slash ? "" : "/"}`,
		method: "get",
		params,
		paramsSerializer: (params) => parseParams(params),
	})

type APIEntityCall<T> = (id: number, params: any) => AxiosPromise<T>

export const getAgreement: APIEntityCall<AgreementData> = getEntity(
	"agreements"
)
export const getTeam: APIEntityCall<TeamData> = getEntity("teams")
export const getUser: APIEntityCall<UserData> = getEntity("users")
export const getAgency = getEntity("agencies")
export const getCampaignDefaults = get("campaigndefaults", true)
export const getCampaignStatusSummary = get("campaigns/status_summary")
export const getGeo = getEntity("geos")
export const getInventory = getEntity("inventories")
export const getFinance = (data: any) =>
	request({
		url: "/finance",
		method: "post",
		data,
		responseType: data.xlsx ? "blob" : "json",
	})
export const getOps = (data: any) =>
	request({
		url: "/report/ops",
		method: "post",
		data,
		responseType: data.xlsx ? "blob" : "json",
	})
export const getGMVMOps = (data: any) =>
	request({
		url: "/report/gmvm_ops",
		method: "post",
		data,
		responseType: "blob",
	})
export const getReportingLastUpdated = () =>
	request({
		url: "/reporting_last_updated",
		method: "get",
	})

export const getAvailableFormats = (id: number) =>
	get(`inventories/${id}/available_formats`)({})

export const userGetAgency = get("agency")

const create = (entity: string) => (data: any) =>
	request({
		url: `/${entity}/`,
		method: "post",
		data,
	})
