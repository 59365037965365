import { differenceInCalendarDays, format, parseISO } from "date-fns"
import { getAgreementPrices } from "./api"
import {
	CampaignStatus,
	FormatType,
	Device,
	Format,
	campaignStatusDisplay,
	formatDisplay,
	PricingType,
} from "./index"
import { AgreementPriceLineData, ICampaign, InventoryData } from "./types"

export function canDelete(status: CampaignStatus) {
	return [CampaignStatus.Draft, CampaignStatus.Pending].includes(status)
}

export enum Status {
	Draft = 1,
	Active,
	Paused,
}

export function lineItemStatusDisplay(s: Status) {
	if (s === Status.Draft) return "Draft"
	if (s === Status.Active) return "Live"
	if (s === Status.Paused) return "Paused"
}

export interface LIInventory {
	name: string
	is_bundle: boolean
	websites: string[] | null
}

/** LineItem model with some nullable values for create form */
export interface LineItem {
	id?: number
	start_date: Date | string | null
	end_date: Date | string | null
	formats: Format[]
	devices: Device[]
	budget: number
	inventory: LIInventory
	impressions?: number
	cpm: number
	gross_cpm: number
	gross_spend?: number
	context: string
	is_active: boolean
}

export interface LineItemData extends LineItem {
	id: number
	start_date: string
	end_date: string
	impressions: number
	cpm: number
	gross_cpm: number
	gross_spend: number
	context: string
}

export interface LineItemUI extends LineItem {
	id: number
	start_date: Date
	end_date: Date
	modified: boolean
	selected: boolean
	creatives: any[]
	auto_priority: boolean
	priority: number
	pacing: number | null
	daily_budget: number
	starting_hour: string | null
	ending_hour: string | null
	active_days: number[]
}

export function formatsAndDevices(l: LineItem): string {
	return (
		l.formats.map(formatDisplay).join(", ") +
		" - " +
		l.devices.map((d) => Device[d]).join(", ")
	)
}
