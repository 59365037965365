/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'click': {
    width: 16,
    height: 16,
    viewBox: '0 0 512.043 512.043',
    data: '<path pid="0" d="M444.902 365.824L231.569 152.491c-3.072-3.029-7.637-3.968-11.627-2.304a10.667 10.667 0 00-6.592 9.856v295.381c0 4.075 2.325 7.787 5.995 9.579 3.669 1.792 8.043 1.344 11.221-1.173l54.165-42.347 47.275 85.077a10.689 10.689 0 0014.123 4.352l64-32a10.641 10.641 0 005.419-6.379 10.821 10.821 0 00-.853-8.341l-44.544-80.149h67.2c4.309 0 8.192-2.603 9.856-6.592a10.645 10.645 0 00-2.305-11.627zm-92.864-3.093a10.674 10.674 0 00-9.195 5.269 10.643 10.643 0 00-.149 10.581l47.936 86.251-44.907 22.464-48.363-87.083a10.7 10.7 0 00-7.211-5.291 10.711 10.711 0 00-8.704 2.048l-46.763 36.565V185.792l176.939 176.939h-59.583zM224.017 0c-5.888 0-10.667 4.779-10.667 10.667v64c0 5.888 4.779 10.667 10.667 10.667s10.667-4.779 10.667-10.667v-64C234.683 4.779 229.905 0 224.017 0zM138.683 149.333h-64c-5.888 0-10.667 4.779-10.667 10.667s4.779 10.667 10.667 10.667h64c5.888 0 10.667-4.779 10.667-10.667s-4.779-10.667-10.667-10.667zM373.35 149.333h-64c-5.888 0-10.667 4.779-10.667 10.667s4.779 10.667 10.667 10.667h64c5.888 0 10.667-4.779 10.667-10.667s-4.779-10.667-10.667-10.667zM171.217 92.117l-45.269-45.248c-4.16-4.16-10.923-4.16-15.083 0s-4.16 10.923 0 15.083l45.269 45.248a10.716 10.716 0 007.552 3.115c2.731 0 5.461-1.024 7.531-3.115 4.16-4.16 4.16-10.923 0-15.083zM171.217 212.821c-4.16-4.16-10.923-4.16-15.083 0l-45.269 45.248c-4.16 4.16-4.16 10.923 0 15.083 2.091 2.069 4.821 3.115 7.552 3.115s5.461-1.045 7.531-3.115l45.269-45.248c4.16-4.16 4.16-10.923 0-15.083zM337.169 46.869c-4.16-4.181-10.923-4.181-15.104 0l-45.269 45.248c-4.16 4.16-4.16 10.923 0 15.083a10.716 10.716 0 007.552 3.115c2.731 0 5.461-1.024 7.552-3.115l45.269-45.248c4.16-4.16 4.16-10.901 0-15.083z"/>'
  }
})
