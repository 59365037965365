import {
	APIListResponse,
	APIPaginatedResponse,
	APIResponse,
	create,
	get,
	list,
	lookup,
	update,
} from "./base"
import { LocationData } from "./types"

const ENDPOINT = "locations"
export const getLocations: APIPaginatedResponse<LocationData> = list(ENDPOINT)
export const getLocation: APIResponse<LocationData> = get(ENDPOINT)
export const lookupLocations = lookup(ENDPOINT)

export const createLocation = create(ENDPOINT)
export const updateLocation = update(ENDPOINT)
