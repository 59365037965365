import { APIPaginatedResponse, create, list, lookup, put, update } from "./base"
import { AgreementStatus, Device, Format, Lookup } from "../index"
import { AgreementPriceData, InventoryData } from "./types"

export interface AgreementData {
	id: number
	agency?: Lookup
	inventory: InventoryData
	cpm: number
	status: AgreementStatus
	description: number
	formats: Format[]
	devices: Device[]
	prices: AgreementPriceData[]
	contexts: string[]
}

export const getAgreements: APIPaginatedResponse<AgreementData> = list(
	"agreements"
)
export const createAgreement = create("agreements")
export const updateAgreement = update("agreements")
