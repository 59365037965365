

















import pathToRegexp from "path-to-regexp"
import { Component, Vue, Watch } from "vue-property-decorator"
import { RouteRecord, Route } from "vue-router"

@Component({
	name: "Breadcrumb",
})
export default class extends Vue {
	private breadcrumbs: RouteRecord[] = []

	@Watch("$route")
	private onRouteChange(route: Route) {
		// if you go to the redirect page, do not update the breadcrumbs
		if (route.path.startsWith("/redirect/")) {
			return
		}
		this.getBreadcrumb()
	}

	created() {
		this.getBreadcrumb()
	}

	private getBreadcrumb() {
		let matched = this.$route.matched.filter(
			(item) => item.meta && item.meta.title
		)
		const first = matched[0]
		if (!this.isDashboard(first)) {
			matched = [
				{ path: "/", meta: { title: "Dashboard" } } as RouteRecord,
			].concat(matched)
		}
		this.breadcrumbs = matched.filter((item) => {
			return item.meta && item.meta.title && item.meta.breadcrumb !== false
		})
	}

	private isDashboard(route: RouteRecord) {
		const name = route && route.meta && route.meta.title
		return name === "Dashboard"
	}

	private pathCompile(path: string) {
		// To solve this problem https://github.com/PanJiaChen/vue-element-admin/issues/561
		const { params } = this.$route
		const toPath = pathToRegexp.compile(path)
		return toPath(params)
	}

	private handleLink(item: any) {
		const { redirect, path } = item
		if (redirect) {
			this.$router.push(redirect)
			return
		}
		this.$router.push(this.pathCompile(path))
	}
}
