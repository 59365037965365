/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'stork': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M10.594 0c-1.5 0-1.489 1.488-1.188 2.188.301.601 2.98 6.101 1.781 7.5C10.387 10.585 9 9 6.5 9 4 9 2.398 10.7 1 9c0 2.7 2.688 3.594 2.688 3.594s1.8-1.281 4.5-1.281C5.288 12.113 4 13.5 3 15c.3.2 2.8 1 5 1 3.602 0 5-5 5-6.5 0-1.898-1.3-5.992-1.5-6.594 2-.699 6.594-1.594 6.594-1.594C15.992.914 12 1 12 1s-.105-1-1.406-1zm7.219 2.406l-1.125.406c.101 1 .007 2.79-1.094 4.188-.801 1-.805 2.508.094 3.406 1 1 2.601 1 3.5 0 .101-.101.218-.21.218-.312l.188-.188s-.008-.094.093-.094C20.488 8.212 21 6.406 21 6.406L20.406 6c.399 0 .79-.105 1.188-.406.601-.602.601-1.492 0-2.094-.602-.602-1.492-.602-2.094 0-.398.2-.5.695-.5 1.094-.102 0-1.188-2.188-1.188-2.188zm-11 14.5l-.407.688a.617.617 0 000 .593l1.938 4.344C7.547 23.125 7.313 24 7.313 24s1.175-.688 2.375-.688c1.8 0 3.812.688 3.812.688-.523-.676-1.047-1.02-1.656-1.188.5.094.844.188.844.188s-.79-1-2.688-1c-.207 0-.387.031-.563.063l-2.03-3.875 1-1.188H8c-.398 0-.79.008-1.188-.094z"/>'
  }
})
