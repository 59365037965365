/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'box': {
    width: 96,
    height: 91.107,
    viewBox: '0 0 96 91.107',
    data: '<path pid="0" overflow="visible" d="M35 0L0 16l10.625 12.406 35.938-15.968zm26 0L49.437 12.438l35.938 15.968L96 16zM48 16.188L16.937 30 48 43.781 79.063 30zM10.656 31.563L0 44l35 16 11.563-12.469C34.615 42.16 22.584 36.972 10.656 31.563zm74.688 0c-11.959 5.345-23.936 10.65-35.906 15.968L61 60.001l35-16zM12 53.874v22.107l34 15.125V54.031l-8.063 8.688-1.968 2.094-2.625-1.188zm72 0l-21.344 9.75-2.625 1.188-1.968-2.094L50 54.03v37.045l34-15.094z" color="#000"/>'
  }
})
