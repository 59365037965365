import {
	APIPaginatedResponse,
	create,
	get,
	getHistory,
	list,
	lookup,
	update,
} from "./base"
import { UserData } from "./types"

const ENDPOINT = "users"

export const getUsers = list(ENDPOINT)
export const getUser = get(ENDPOINT)
export const lookupUsers = lookup(ENDPOINT)

export const createUser = create(ENDPOINT)
export const updateUser = update(ENDPOINT)
