import { RouteConfig } from "vue-router"
import Layout from "@/layout/index.vue"

const campaignRouter: RouteConfig = {
	path: "/campaigns",
	component: Layout,
	meta: {
		title: "Campaigns",
		roles: ["campaign viewer", "admin"],
	},
	children: [
		{
			path: "",
			component: () =>
				import(
					/* webpackChunkName: "campaignlist" */ "@/views/campaigns/index.vue"
				),
			meta: {
				title: "Campaigns",
				icon: "campaign",
				breadcrumb: false,
			},
		},
		{
			path: "create",
			component: () =>
				import(
					/* webpackChunkName: "createcampaign" */ "@/views/campaigns/detail.vue"
				),
			meta: {
				title: "Create Campaign",
				roles: ["campaign editor", "admin"],
				hidden: true,
			},
		},
		{
			path: "history/:id(\\d+)",
			component: () =>
				import(
					/* webpackChunkName: "viewcampaignhistory" */ "@/views/campaigns/history/index.vue"
				),
			meta: {
				title: "View Campaign History",
				hidden: true,
			},
		},
		{
			path: "edit/:id(\\d+)",
			component: () =>
				import(
					/* webpackChunkName: "editcampaign" */ "@/views/campaigns/detail.vue"
				),
			meta: {
				title: "Edit Campaign",
				roles: ["campaign editor", "admin"],
				hidden: true,
			},
		},
	],
}

export default campaignRouter
