






















import { Component, Watch } from "vue-property-decorator"
import { mixins } from "vue-class-component"
import { DeviceType, AppModule } from "@/store/modules/app"
import { Navbar, Sidebar } from "./components"
import ResizeMixin from "./mixin/resize"

@Component({
	name: "Layout",
	components: {
		Navbar,
		Sidebar,
	},
})
export default class extends mixins(ResizeMixin) {
	private loading = false

	@Watch("$route")
	onRouteChanged(to, from) {
		this.loading = false
	}

	get classObj() {
		return {
			hideSidebar: !this.sidebar.opened,
			openSidebar: this.sidebar.opened,
			withoutAnimation: this.sidebar.withoutAnimation,
			mobile: this.device === DeviceType.Mobile,
		}
	}

	private handleClickOutside() {
		AppModule.CloseSideBar(false)
	}

	get releaseVersion() {
		return process.env.VUE_APP_VERSION
	}
}
