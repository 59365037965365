import { create, Lookup, update } from "./base"
import { Device, Format, LIInventory } from "@/select"

export interface AgreementPriceData {
	id: number
	value: number
	pricing_model: number
	agreement: number
}

export interface AgreementPriceAutoData {
	id: number
	inventory: LIInventory
	value: number
	pricing_model: number
	agreement: number
	formats: Format[]
	devices: Device[]
	contexts: Lookup[]
	cpm: number
	cpm_margin: number
}

export const createAgreementprice = create("agreementprices")
export const updateAgreementprice = update("agreementprices")
