import { Agreement } from "./types"
export enum Status {
	Draft = 1,
	UnderReview,
	Approved,
	NotApproved,
	Ended,
}

export function statusDisplay(s: Status) {
	if (s === Status.Draft) return "Draft"
	if (s === Status.UnderReview) return "Under Review"
	if (s === Status.Approved) return "Approved"
	if (s === Status.NotApproved) return "Not Approved"
	if (s === Status.Ended) return "Ended"
}

export const defaultAgreementData: Agreement = {
	id: null,
	inventory: null,
	formats: [],
	devices: [],
	cpm: 0,
	prices: [],
	status: Status.Draft,
	contexts: [],
}
