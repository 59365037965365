import {
	apiDelete,
	bulkUpdate,
	create,
	get,
	getHistory,
	list,
	put,
	update,
} from "./base"

const ENDPOINT = "campaigncreatives"
export const getCampaignCreativeHistory = getHistory(ENDPOINT)
export const getCampaignCreatives = list(ENDPOINT)
export const getCampaignCreative = get(ENDPOINT)

export const createCampaignCreative = create(ENDPOINT)
export const updateCampaignCreative = update(ENDPOINT)
export const updateCampaignCreatives = bulkUpdate(ENDPOINT)
export const deleteCampaignCreative = apiDelete(ENDPOINT)

export const setCampaignCreativesActive = (data: any) =>
	put(`${ENDPOINT}/set_active`, data)
