export enum PricingType {
	Fixed = 1,
	Percentage,
}

export function pmDisplay(p: PricingType | null): string {
	if (p === PricingType.Fixed) return "currency"
	if (p === PricingType.Percentage) return "percentage"
	return ""
}
