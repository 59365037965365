/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'agreement': {
    width: 16,
    height: 16,
    viewBox: '0 0 512 512',
    data: '<path pid="0" d="M123.733 130.133c-17.067-17.067-89.6-21.333-113.067-23.467-2.133 0-4.267 0-6.4 2.133C2.133 110.933 0 115.2 0 117.333v192C0 315.733 4.267 320 10.667 320h64c4.267 0 8.533-2.133 10.667-6.4 0-6.4 38.4-119.467 42.667-174.933-.001-2.134-.001-6.4-4.268-8.534zM352 181.333c-21.333-6.4-40.533-14.933-57.6-21.333-38.4-17.067-55.467-8.533-89.6 25.6-14.933 14.933-25.6 36.267-23.467 44.8 0 2.133 0 2.133 4.267 4.267 10.667 4.267 25.6 6.4 40.533-17.067 2.133-2.133 4.267-4.267 8.533-4.267 6.4 0 8.533-2.133 14.933-4.267 4.267-2.133 8.533-4.267 14.933-6.4h4.267c2.133 0 6.4 2.133 8.533 2.133C288 215.467 307.2 230.4 326.4 247.467c29.867 23.467 59.733 49.067 74.667 68.267h2.133c-14.933-42.667-40.533-115.201-51.2-134.401zM501.333 128c-83.2 0-130.133 21.333-132.267 21.333l-6.4 6.4c0 2.133 0 6.4 2.133 8.533 12.8 21.333 55.467 138.667 61.867 168.533 2.133 4.267 6.4 8.533 10.667 8.533h64c6.4 0 10.667-4.267 10.667-10.667v-192c0-6.398-4.267-10.665-10.667-10.665z"/><path pid="1" d="M386.133 337.067c-8.533-19.2-44.8-46.933-76.8-72.533C292.267 249.6 275.2 236.8 262.4 226.133c-2.133 2.133-6.4 2.133-6.4 4.267-6.4 2.133-8.533 4.267-17.067 4.267-17.066 21.333-38.4 29.866-61.866 21.333-10.667-2.133-17.067-10.667-19.2-19.2-4.267-21.333 14.933-51.2 29.867-66.133h-42.667c-8.533 42.667-23.467 98.133-34.133 128 8.533 8.533 17.067 19.2 23.467 23.467 40.533 34.133 87.467 68.267 96 74.667 6.4 4.267 19.2 8.533 25.6 8.533h6.4L228.267 371.2c-4.267-4.267-4.267-10.667 0-14.933s10.667-4.267 14.933 0l42.667 42.667c4.267 4.267 8.533 2.133 12.8 2.133 6.4-2.133 8.533-6.4 10.667-12.8L260.267 339.2c-4.267-4.267-4.267-10.667 0-14.933s10.667-4.267 14.933 0l53.333 53.333c2.133 2.133 10.667 2.133 17.067 0 2.133-2.133 6.4-4.267 8.533-8.533L294.4 309.333c-4.267-4.267-4.267-10.667 0-14.933s10.667-4.267 14.933 0l61.867 61.867c4.267 2.133 8.533 0 12.8-2.133 2.133-2.134 6.4-8.534 2.133-17.067z"/>'
  }
})
