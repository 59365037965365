import { FormatType, LineItem, Lookup } from "./index"
import { Creative, ICampaign, LocationData } from "./types"
import { UserModule } from "@/store/modules/user"
import { zeroTime } from "@/utils"

const today = zeroTime(new Date())

export enum Status {
	Draft = 1,
	Pending,
	MissingCreatives,
	Declined,
	Ready,
	Active,
	Paused,
	Ended,
}

export enum FrequencyCapDuration {
	PerHour = 1,
	PerDay,
	PerWeek,
}

export enum LineItemFrequencyCapDuration {
	PerHour = 1,
	PerDay,
	PerWeek,
	PerCampaign,
}

export enum Pacing {
	Even = 1,
	ASAP,
}

export interface Campaign {
	id: number | null
	agency?: Lookup
	advertiser: number | null
	name: string
	start_date: Date | string
	end_date: Date | string | null
	budget: number
	status: Status
	type: FormatType | null
	active_days: number[]
	starting_hour: string
	ending_hour: string
	frequency_cap: number
	frequency_cap_duration: FrequencyCapDuration
	line_item_frequency_cap: number
	line_item_frequency_cap_duration: LineItemFrequencyCapDuration
	pacing: Pacing
	notes: string
	locations: Lookup[]
	cpm_goal: number | null
}

export interface CampaignForm extends Campaign {
	start_date: Date
	end_date: Date | null
}

export interface CampaignData extends Campaign {
	id: number
	advertiser: number
	start_date: string
	end_date: string
	type: FormatType
}

export const defaultCampaignData: Campaign = {
	id: null,
	advertiser: null,
	name: "",
	start_date: today,
	end_date: null,
	type: null,
	budget: 0,
	status: 1,
	active_days: [],
	// line_items: [],
	// creatives: [],
	starting_hour: "00:00",
	ending_hour: "00:00",
	frequency_cap: 8,
	frequency_cap_duration: FrequencyCapDuration.PerHour,
	line_item_frequency_cap: 3,
	line_item_frequency_cap_duration: LineItemFrequencyCapDuration.PerHour,
	pacing: Pacing.Even,
	notes: "",
	// auto_lines: [],
	locations: [],
	cpm_goal: null,
}

export function frequencyCapDurationDisplay(f: FrequencyCapDuration) {
	if (f === FrequencyCapDuration.PerHour) return "Per Hour"
	if (f === FrequencyCapDuration.PerDay) return "Per Day"
	if (f === FrequencyCapDuration.PerWeek) return "Per Week"
}

export function statusDisplay(s: Status) {
	if (s === Status.Draft) return "Draft"
	if (s === Status.Pending) return "Under Review"
	if (s === Status.MissingCreatives) return "Missing Creatives"
	if (s === Status.Declined) return "Rejected"
	if (s === Status.Ready) return "Ready"
	if (s === Status.Active) return "Live"
	if (s === Status.Paused) return "Paused"
	if (s === Status.Ended) return "Ended"
	return `Unknown (${s})`
}
