export interface Inventory {
	id: number | null
	name: string
	is_available: boolean
	is_bundle: boolean
	websites: string[]
	publisher: number | null
	agencies?: string[] | null
}

export interface InventoryData extends Inventory {
	id: number
	publisher: number
	agencies?: string[] | null
}

export const groupInventories = (data: any) => [
	{
		label: "Bundles",
		inventories: data.filter((i) => i.is_bundle),
	},
	{
		label: "Websites",
		inventories: data.filter((i) => !i.is_bundle),
	},
]

export const defaultWebsiteData: Inventory = {
	id: null,
	name: "",
	is_available: true,
	is_bundle: false,
	websites: [],
	publisher: null,
	agencies: [],
	display_contexts: [],
	video_contexts: [],
}
