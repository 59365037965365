
























import { Component, Vue } from "vue-property-decorator"
import { AppModule } from "@/store/modules/app"
import { UserModule } from "@/store/modules/user"
import Breadcrumb from "@/components/Breadcrumb/index.vue"
import Hamburger from "@/components/Hamburger/index.vue"

@Component({
	name: "Navbar",
	components: {
		Breadcrumb,
		Hamburger,
	},
})
export default class extends Vue {
	get sidebar() {
		return AppModule.sidebar
	}

	get device() {
		return AppModule.device.toString()
	}

	get isAdmin() {
		return UserModule.roles.includes("admin")
	}

	get isDemandUser() {
		return UserModule.roles.includes("demand user")
	}

	get name() {
		return UserModule.name
	}

	private toggleSideBar() {
		AppModule.ToggleSideBar(false)
	}

	private async logout() {
		await UserModule.LogOut()
		this.$router.push(`/login?redirect=${this.$route.fullPath}`)
	}
}
