export * from "./api"
export {
	defaultAgreementData,
	Status as AgreementStatus,
	statusDisplay as agreementStatusDisplay,
} from "./agreement"
export {
	defaultCampaignData,
	FrequencyCapDuration,
	frequencyCapDurationDisplay,
	LineItemFrequencyCapDuration,
	Pacing,
	Status as CampaignStatus,
	statusDisplay as campaignStatusDisplay,
} from "./campaign"
export type { Campaign, CampaignData } from "./campaign"
export type { CampaignCreativeData } from "./campaigncreative"
export type { CreativeData } from "./creative"
export { Type as GeoType, typeDisplay as geoTypeDisplay } from "./geo"
export * from "./inventory"
export { formatsAndDevices, Status as LineItemStatus } from "./lineitem"
export type {
	LIInventory,
	LineItem,
	LineItemData,
	LineItemUI,
} from "./lineitem"
export * from "./pricingmodel"
export * from "./errorhandler"

export enum Day {
	Monday = 1,
	Tuesday,
	Wednesday,
	Thursday,
	Friday,
	Saturday,
	Sunday,
}

export enum Device {
	Desktop = 1,
	Mobile,
	App,
}

/* Use formatDisplay instead of reverse mapping */
export enum Format {
	F970X250 = 1,
	F300X600,
	F120X600,
	F160X600,
	F728X90,
	F300X250,
	F320X50,
	Bumper,
	Outstream,
	Instream,
}

export enum FormatType {
	Display = 1,
	Video,
}

export enum NewFormatType {
	Display = "Display",
	Video = "Video",
}

const formatMap = new Map([
	[Format.F970X250, "970X250"],
	[Format.F300X600, "300X600"],
	[Format.F120X600, "120X600"],
	[Format.F160X600, "160X600"],
	[Format.F728X90, "728X90"],
	[Format.F300X250, "300X250"],
	[Format.F320X50, "320X50"],
	[Format.Bumper, "Bumper"],
	[Format.Outstream, "Outstream"],
	[Format.Instream, "Instream"],
])

const SizeMap = {
	108: "970x250",
	18: "300x600",
	2: "300x250",
	4: "728x90",
	42: "320x50",
	1: "120x600",
	6: "160x600",
}

export function formatDisplay(f: Format): string {
	return formatMap.get(f) || `Unknown (${f})`
}

export function formatsDisplay(fs: Format[]): string {
	return fs.map(formatDisplay).join(", ")
}

export function formatType(type: 1 | 2): string {
	return type === 1 ? "Display" : "Video"
}

export enum AuditStatus {
	PENDING = 1,
	APPROVED = 2,
	REJECTED = 3,
}
