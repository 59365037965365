import Vue from "vue"
import Router, { RouteConfig } from "vue-router"

/* Layout */
import Layout from "@/layout/index.vue"
import LoginLayout from "@/layout/login.vue"
import WebsiteDetail from "@/views/inventories/detail.vue"

import advertiserRouter from "./modules/advertiser"
import agreementRouter from "./modules/agreement"
import campaignRouter from "./modules/campaign"
import teamRouter from "./modules/team"
import userRouter from "./modules/user"

Vue.use(Router)

/*
  Note: sub-menu only appear when children.length>=1
  Detail see: https://panjiachen.github.io/vue-element-admin-site/guide/essentials/router-and-nav.html
*/

/*
  name:'router-name'             the name field is required when using <keep-alive>, it should also match its component's name property
                                 detail see : https://vuejs.org/v2/guide/components-dynamic-async.html#keep-alive-with-Dynamic-Components
  redirect:                      if set to 'noredirect', no redirect action will be trigger when clicking the breadcrumb
  meta: {
    roles: ['admin', 'editor']   will control the page permissions (allow setting multiple permissions)
    title: 'title'               the name showed in subMenu and breadcrumb (recommend set)
    icon: 'svg-name'             the icon showed in the sidebar
    hidden: true                 if true, this route will not show in the sidebar (default is false)
    alwaysShow: true             if true, will always show the root menu (default is false)
                                 if false, hide the root menu when has less or equal than one children route
    breadcrumb: false            if false, the item will be hidden in breadcrumb (default is true)
    noCache: true                if true, the page will not be cached (default is false)
    affix: true                  if true, the tag will affix in the tags-view
    activeMenu: '/example/list'  if set path, the sidebar will highlight the path you set
  }
*/

/**
  ConstantRoutes
  a base page that does not have permission requirements
  all permissions can be accessed
*/
export const constantRoutes: RouteConfig[] = [
	{
		path: "/dashboard",
		redirect: "/",
		meta: { hidden: true },
	},
	{
		path: "/login",
		component: LoginLayout,
		meta: {
			title: "Login",
			hidden: true,
		},
		children: [
			{
				path: "",
				component: () =>
					import(/* webpackChunkName: "login" */ "@/views/login/index.vue"),
				meta: {
					title: "Login",
					hidden: true,
				},
			},
		],
	},
	{
		path: "/reset_password",
		component: LoginLayout,
		meta: {
			title: "Reset Password",
			hidden: true,
		},
		children: [
			{
				path: "",
				component: () =>
					import(
						/* webpackChunkName: "reset_password" */ "@/views/reset_password/index.vue"
					),
				meta: {
					title: "Reset Password",
					hidden: true,
				},
			},
		],
	},
	{
		path: "/reset_password_confirm/:uid/:token",
		component: LoginLayout,
		meta: {
			title: "Confirm Password Reset",
			hidden: true,
		},
		children: [
			{
				path: "",
				component: () =>
					import(
						/* webpackChunkName: "reset_password_confirm" */ "@/views/reset_password_confirm/index.vue"
					),
				meta: {
					title: "Confirm Password Reset",
					hidden: true,
				},
			},
		],
	},
	{
		path: "/404",
		component: () => import(/* webpackChunkName: "404" */ "@/views/404.vue"),
		meta: { hidden: true },
	},

	{
		path: "/changepassword",
		component: Layout,
		meta: {
			hidden: true,
		},
		children: [
			{
				path: "",
				component: () =>
					import(
						/* webpackChunkName: "changepassword" */ "@/views/changepassword.vue"
					),
				name: "Change Password",
				meta: {
					title: "Change Password",
					hidden: true,
				},
			},
		],
	},
]

/**
 * asyncRoutes
 * the routes that need to be dynamically loaded based on user permissions
 */
export const asyncRoutes: RouteConfig[] = [
	{
		//Public Users
		path: "/",
		component: Layout,
		meta: {
			title: "Dashboard",
			icon: "dashboard",
			roles: ["demand user", "supply user"],
		},
		children: [
			{
				path: "",
				component: () =>
					import(
						/* webpackChunkName: "dashboard" */ "@/views/dashboard/index.vue"
					),
				meta: {
					title: "Dashboard",
					icon: "dashboard",
				},
			},

			{
				path: "/trading",
				component: () =>
					import(
						/* webpackChunkName: "dashboard" */ "@/views/dashboard/SupplyTrading.vue"
					),
				meta: {
					title: "Trading",
					icon: "dashboard",
					roles: ["supply user", "admin"],
				},
			},
		],
	},
	{
		//Admin Users
		path: "/",
		component: Layout,
		meta: {
			title: "Dashboard",
			icon: "dashboard",
			roles: ["admin"],
		},
		children: [
			{
				path: "",
				component: () =>
					import(
						/* webpackChunkName: "dashboard" */ "@/views/dashboard/DemandDelivery.vue"
					),
				meta: {
					title: "Campaign Delivery",
					icon: "dashboard",
				},
			},
			{
				path: "supply",
				component: () =>
					import(
						/* webpackChunkName: "dashboard" */ "@/views/dashboard/SupplyDelivery.vue"
					),
				meta: {
					title: "Publisher Delivery",
					icon: "dashboard",
				},
			},
		],
	},
	campaignRouter,

	{
		path: "/reporting",
		component: Layout,
		meta: {
			title: "Reporting",
			icon: "chart",
			roles: ["reporting user"],
		},
		children: [
			{
				path: "",
				component: () =>
					import(
						/* webpackChunkName: "reporting" */ "@/views/reporting/index.vue"
					),
				name: "Reporting",
				meta: {
					title: "Reporting",
					icon: "chart",
				},
			},
		],
	},
	{
		path: "/reporting",
		component: Layout,
		meta: {
			title: "Reporting",
			icon: "chart",
			roles: ["admin"],
		},
		children: [
			{
				path: "",
				component: () =>
					import(
						/* webpackChunkName: "reporting" */ "@/views/reporting/index.vue"
					),
				name: "Reporting",
				meta: {
					title: "Report Builder",
					icon: "dashboard",
				},
			},
			{
				path: "/ops",
				component: () =>
					import(/* webpackChunkName: "ops" */ "@/views/ops/index.vue"),
				name: "Ops",
				meta: {
					title: "Ops",
					icon: "eye-on",
					roles: ["admin"],
				},
			},
		],
	},
	{
		path: "/finance",
		component: Layout,
		meta: {
			roles: ["finance user", "admin"],
		},
		children: [
			{
				path: "",
				component: () =>
					import(/* webpackChunkName: "finance" */ "@/views/finance/index.vue"),
				name: "Finance",
				meta: {
					title: "Finance",
					icon: "euro",
				},
			},
		],
	},
	{
		path: "/invoice",
		component: Layout,
		meta: {
			roles: ["admin"],
		},
		children: [
			{
				path: "",
				component: () =>
					import(/* webpackChunkName: "finance" */ "@/views/invoice.vue"),
				name: "Invoice",
				meta: {
					title: "Invoice",
					icon: "euro",
				},
			},
		],
	},
	agreementRouter,
	advertiserRouter,
	teamRouter,
	userRouter,
	{
		path: "/account",
		component: Layout,
		meta: {
			title: "Account Settings",
			roles: ["agency admin"],
			alwaysShow: true,
		},
		children: [
			{
				path: "",
				component: () =>
					import(
						/* webpackChunkName: "agencydetail" */ "@/views/agencies/detail.vue"
					),
				meta: {
					title: "Account Settings",
					breadcrumb: false,
					icon: "settings",
				},
			},
		],
	},
	{
		path: "/agencies",
		component: Layout,
		meta: {
			title: "Agencies",
			roles: ["admin"],
			alwaysShow: true,
		},
		children: [
			{
				path: "",
				component: () =>
					import(
						/* webpackChunkName: "agencylist" */ "@/views/agencies/index.vue"
					),
				meta: {
					title: "Agencies",
					icon: "nested",
					breadcrumb: false,
				},
			},
			{
				path: "create",
				component: () =>
					import(
						/* webpackChunkName: "agencydetail" */ "@/views/agencies/detail.vue"
					),
				meta: {
					title: "Create Agency",
					hidden: true,
				},
			},
			{
				path: "edit/:id(\\d+)",
				component: () =>
					import(
						/* webpackChunkName: "agencydetail" */ "@/views/agencies/detail.vue"
					),
				meta: {
					title: "Edit Agency",
					hidden: true,
				},
			},
		],
	},
	{
		path: "/publishers",
		component: Layout,
		meta: {
			title: "Publishers",
			roles: ["admin"],
			alwaysShow: true,
		},
		children: [
			{
				path: "",
				component: () =>
					import(
						/* webpackChunkName: "publisher" */ "@/views/supply/publishers/index.vue"
					),
				meta: {
					title: "Publishers",
					icon: "international",
					breadcrumb: false,
				},
			},
			{
				path: "create",
				component: () =>
					import(
						/* webpackChunkName: "publisher" */ "@/views/supply/publishers/detail.vue"
					),
				meta: {
					title: "Create Publisher",
					hidden: true,
				},
			},
			{
				path: "edit/:id(\\d+)",
				component: () =>
					import(
						/* webpackChunkName: "publisher" */ "@/views/supply/publishers/detail.vue"
					),
				meta: {
					title: "Edit Publisher",
					hidden: true,
				},
			},
		],
	},
	{
		path: "/inventories",
		component: Layout,
		meta: {
			title: "Sites & Bundles",
			roles: ["admin"],
			alwaysShow: true,
		},
		children: [
			{
				path: "",
				component: () =>
					import(
						/* webpackChunkName: "inventorylist" */ "@/views/inventories/index.vue"
					),
				meta: {
					title: "Sites & Bundles",
					icon: "international",
					breadcrumb: false,
				},
			},
			{
				path: "create",
				component: () =>
					import(
						/* webpackChunkName: "websitedetail" */ "@/views/inventories/detail.vue"
					),
				meta: {
					title: "Create Inventory",
					hidden: true,
				},
			},
			{
				path: "edit/:id(\\d+)",
				component: () =>
					import(
						/* webpackChunkName: "websitedetail" */ "@/views/inventories/detail.vue"
					),
				meta: {
					title: "Edit Inventory",
					hidden: true,
				},
			},
		],
	},
	{
		path: "/bundles",
		component: Layout,
		meta: {
			title: "Bundles",
			roles: ["bundle editor"],
			alwaysShow: true,
		},
		children: [
			{
				path: "",
				component: () =>
					import(
						/* webpackChunkName: "inventorylist" */ "@/views/supply/bundles/index.vue"
					),
				meta: {
					title: "Bundles",
					icon: "international",
					breadcrumb: false,
				},
			},
			{
				path: "create",
				component: () =>
					import(
						/* webpackChunkName: "websitedetail" */ "@/views/supply/bundles/detail.vue"
					),
				meta: {
					title: "Create Bundle",
					hidden: true,
				},
			},
			{
				path: "edit/:id(\\d+)",
				component: () =>
					import(
						/* webpackChunkName: "websitedetail" */ "@/views/supply/bundles/detail.vue"
					),
				meta: {
					title: "Edit Bundle",
					hidden: true,
				},
			},
		],
	},
	{
		path: "/taskresults",
		component: Layout,
		meta: {
			title: "Task Log",
			roles: ["admin"],
			alwaysShow: true,
		},
		children: [
			{
				path: "",
				component: () =>
					import(
						/* webpackChunkName: "taskresultslist" */ "@/views/admin/taskresults/index.vue"
					),
				meta: {
					title: "Task Log",
					icon: "skill",
					breadcrumb: false,
				},
			},
			{
				path: ":id",
				component: () =>
					import(
						/* webpackChunkName: "taskresultsdetail" */ "@/views/admin/taskresults/detail.vue"
					),
				meta: {
					title: "View Task Result",
					hidden: true,
				},
			},
		],
	},
	{
		path: "/zones",
		component: Layout,
		meta: {
			title: "Zones",
			roles: ["admin"],
			alwaysShow: true,
		},
		children: [
			{
				path: "",
				component: () =>
					import(
						/* webpackChunkName: "zonelist" */ "@/views/supply/zones/index.vue"
					),
				meta: {
					title: "Zones",
					icon: "international",
					breadcrumb: false,
				},
			},
			{
				path: "create",
				component: () =>
					import(
						/* webpackChunkName: "zonedetail" */ "@/views/supply/zones/detail.vue"
					),
				meta: {
					title: "Create Zone",
					hidden: true,
				},
			},
			{
				path: "edit/:id(\\d+)",
				component: () =>
					import(
						/* webpackChunkName: "zonedetail" */ "@/views/supply/zones/detail.vue"
					),
				meta: {
					title: "Edit Zone",
					hidden: true,
				},
			},
		],
	},
	{
		path: "/passbacks",
		component: Layout,
		meta: {
			title: "Passbacks",
			roles: ["admin"],
			alwaysShow: true,
		},
		children: [
			{
				path: "",
				component: () =>
					import(
						/* webpackChunkName: "passback" */ "@/views/supply/passbacks/index.vue"
					),
				meta: {
					title: "Passbacks",
					icon: "international",
					breadcrumb: false,
				},
			},
			{
				path: "create",
				component: () =>
					import(
						/* webpackChunkName: "passback" */ "@/views/supply/passbacks/detail.vue"
					),
				meta: {
					title: "Create Passback",
					hidden: true,
				},
			},
			{
				path: "edit/:id(\\d+)",
				component: () =>
					import(
						/* webpackChunkName: "passback" */ "@/views/supply/passbacks/detail.vue"
					),
				meta: {
					title: "Edit Passback",
					hidden: true,
				},
			},
		],
	},
	{
		path: "/creatives",
		component: Layout,
		meta: {
			title: "Creatives",
			roles: ["admin", "agency admin", "team admin"],
			alwaysShow: true,
		},
		children: [
			{
				path: "",
				component: () =>
					import(
						/* webpackChunkName: "creative" */ "@/views/creatives/index.vue"
					),
				meta: {
					title: "Creatives",
					icon: "international",
					breadcrumb: false,
				},
			},
			{
				path: "create",
				component: () =>
					import(
						/* webpackChunkName: "creative" */ "@/views/creatives/detail.vue"
					),
				meta: {
					title: "Create Creative",
					hidden: true,
				},
			},
			{
				path: "edit/:id(\\d+)",
				component: () =>
					import(
						/* webpackChunkName: "creative" */ "@/views/creatives/detail.vue"
					),
				meta: {
					title: "Edit Creative",
					roles: ["admin"],
					hidden: true,
				},
			},
		],
	},
	{
		path: "/audits",
		component: Layout,
		meta: {
			title: "Audits",
			roles: ["admin", "auditor"],
			alwaysShow: true,
		},
		children: [
			{
				path: "",
				component: () =>
					import(/* webpackChunkName: "audits" */ "@/views/audits/index.vue"),
				meta: {
					title: "Audits",
					icon: "international",
					breadcrumb: false,
				},
			},
		],
	},
	{
		path: "/contexts",
		component: Layout,
		meta: {
			title: "Contexts",
			roles: ["admin", "agency admin"],
			alwaysShow: true,
		},
		children: [
			{
				path: "",
				component: () =>
					import(
						/* webpackChunkName: "context" */ "@/views/contexts/index.vue"
					),
				meta: {
					title: "Contexts",
					icon: "international",
					breadcrumb: false,
				},
			},
			{
				path: "create",
				component: () =>
					import(
						/* webpackChunkName: "context" */ "@/views/contexts/detail.vue"
					),
				meta: {
					title: "Create Context",
					hidden: true,
				},
			},
			{
				path: "edit/:id",
				component: () =>
					import(
						/* webpackChunkName: "context" */ "@/views/contexts/detail.vue"
					),
				meta: {
					title: "Edit Context",
					hidden: true,
				},
			},
		],
	},
	{
		path: "/updates",
		component: Layout,
		meta: {
			hidden: true,
		},
		children: [
			{
				path: "2021_09_28",
				component: () => import("@/views/updates/2021_09_28.vue"),
				meta: {
					title: "Update - Campaign Revamp",
					roles: ["admin", "demand user"],
					hidden: true,
				},
			},
		],
	},
	{
		path: "*",
		redirect: "/404",
		meta: { hidden: true },
	},
]

const createRouter = () =>
	new Router({
		mode: "history", // Disabled due to Github Pages doesn't support this, enable this if you need.
		scrollBehavior: (to, from, savedPosition) => {
			if (savedPosition) {
				return savedPosition
			} else {
				return { x: 0, y: 0 }
			}
		},
		base: process.env.BASE_URL,
		routes: constantRoutes,
	})

const router = createRouter()

// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
export function resetRouter() {
	const newRouter = createRouter()
	;(router as any).matcher = (newRouter as any).matcher // reset router
}

export default router
