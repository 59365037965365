import request from "@/utils/request"
import {
	APIPaginatedResponse,
	APIResponse,
	apiDelete,
	bulkUpdate,
	create,
	get,
	list,
	lookup,
	put,
} from "./base"
import { CampaignData } from "../campaign"

const ENDPOINT = "campaigns"
type CampaignList = APIPaginatedResponse<CampaignData>
export const getCampaigns: CampaignList = list(ENDPOINT)
export const getCampaign: APIResponse<CampaignData> = get(ENDPOINT)
export const lookupCampaigns = lookup("campaigns")
export const createCampaign = create(ENDPOINT)
export const updateCampaign = (
	id: number,
	data: any,
	partial = false,
	sync = false
) =>
	request({
		url: `/campaigns/${id}/`,
		method: partial ? "patch" : "put",
		data,
		params: {
			sync: sync ? 1 : undefined,
		},
	})
export const deleteCampaign = apiDelete(ENDPOINT)

export const getCampaignDefaults = get("campaigndefaults")

const campaignAction = (
	method: any,
	action: string,
	data = {},
	params = {}
) => (id: number) => method(`${ENDPOINT}/${id}/${action}`, data, params)

export const submitCampaign = campaignAction(put, "submit")
export const setCampaignActive = (active: boolean) =>
	campaignAction(put, "set_active", { is_active: active })
export const getLineBudgetSums = campaignAction(list, "line_budget_sums")
export const reviewCampaign = (
	approved: boolean,
	message: string,
	sync: boolean
) =>
	campaignAction(
		put,
		"review",
		{ approved, message },
		{ sync: sync ? 1 : undefined }
	)
