
























import { Component, Prop, Vue } from "vue-property-decorator"
import { AppModule } from "@/store/modules/app"
import { PermissionModule } from "@/store/modules/permission"
import SidebarItem from "./SidebarItem.vue"
import variables from "@/styles/_variables.scss"

@Component({
	name: "SideBar",
	components: {
		SidebarItem,
	},
})
export default class extends Vue {
	get sidebar() {
		return AppModule.sidebar
	}

	get routes() {
		return PermissionModule.routes
	}

	get variables() {
		return variables
	}

	get activeMenu() {
		const { meta, path } = this.$route
		// if set path, the sidebar will highlight the path you set
		if (meta.activeMenu) {
			return meta.activeMenu
		}
		return path
	}

	get isCollapse() {
		return !this.sidebar.opened
	}
}
