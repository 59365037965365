/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'checkmark': {
    width: 384,
    height: 384,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M9 19.4l-5.7-5.7 1.4-1.4L9 16.6 20.3 5.3l1.4 1.4z"/>'
  }
})
