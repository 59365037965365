import {
	APIPaginatedResponse,
	bulkUpdate,
	create,
	get,
	getHistory,
	list,
	lookup,
	update,
} from "./base"
import { AuditData } from "./types"

const ENDPOINT = "audits"
export const getAuditHistory = getHistory(ENDPOINT)
export const getAudits = list(ENDPOINT)
export const getAudit = get(ENDPOINT)

export const createAudit = create(ENDPOINT)
export const updateAudit = update(ENDPOINT)
export const updateAudits = bulkUpdate(ENDPOINT)
