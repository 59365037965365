import { APIPaginatedResponse, create, list, lookup, put, update } from "./base"
import { FrequencyCapDuration, LineItemFrequencyCapDuration } from "../campaign"
import { LocationData } from "./types"

export interface Agency {
	name: string
	serving_fee_included_in_cpm: boolean
	display_adserving_fee: number
	video_adserving_fee: number
	pricing_models: any[]
	active_days: number[]
	fixed_active: boolean
	percentage_active: boolean
	starting_hour: string
	ending_hour: string
	frequency_cap: number
	frequency_cap_duration: number
	line_item_frequency_cap: number
	line_item_frequency_cap_duration: number
	countries: LocationData[]
}

export interface AgencyData extends Agency {
	id: number
}

export interface AgencyUI extends Agency {
	id: number | null
}

export const defaultAgencyData: AgencyUI = {
	id: null,
	name: "",
	serving_fee_included_in_cpm: false,
	display_adserving_fee: 0,
	video_adserving_fee: 0,
	starting_hour: "00:00",
	ending_hour: "00:00",
	frequency_cap: 1,
	frequency_cap_duration: FrequencyCapDuration.PerHour,
	line_item_frequency_cap: 1,
	line_item_frequency_cap_duration: LineItemFrequencyCapDuration.PerHour,
	active_days: [],
	fixed_active: false,
	percentage_active: false,
	pricing_models: [],
	countries: [],
}

export const getAgencies: APIPaginatedResponse<AgencyData> = list("agencies")
export const lookupAgencies = lookup("agencies")
export const createAgency = create("agencies")
export const updateAgency = update("agencies")
export const userUpdateAgency = (data) => put("agency", data)
