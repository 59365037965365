import { AxiosPromise } from "axios"
import request from "@/utils/request"

export interface Lookup {
	id: number
	name: string
}

interface PaginatedResponse<T> {
	count: number
	next: string | null
	previous: string | null
	results: T[]
}

export type APIListResponse<T> = (params?: any) => AxiosPromise<T[]>
export type APIPaginatedResponse<T> = (
	params?: any
) => AxiosPromise<PaginatedResponse<T>>
export type APIResponse<T> = (id: number, params?: any) => AxiosPromise<T>

export const apiDelete = (entity: string) => (id: number) =>
	request({
		url: `${entity}/${id}/`,
		method: "delete",
	})

export const bulkDelete = (entity: string) => (ids: number[]) =>
	request({
		url: `${entity}/`,
		method: "delete",
		data: ids,
	})

export const get = (baseUrl: string) => (id: number, params: any) =>
	request({
		url: `/${baseUrl}/${id}/`,
		method: "get",
		params,
		paramsSerializer: (params) => parseParams(params),
	})

export const list = (entity: string) => (params: any = {}) =>
	request({
		url: `/${entity}/`,
		method: "get",
		params,
		paramsSerializer: (params) => parseParams(params),
	})

export const listAll = (entity: string) => (params: any = {}) =>
	list(entity)({ ...params, page_size: 0 })

export const lookup = (entity: string) => (
	params: any = {}
): AxiosPromise<Lookup[]> =>
	request({
		url: `/${entity}/`,
		method: "get",
		params: { ...params, lookup: 1 },
	})

const postOrPut = (method: "post" | "put") => (url: string, data: any) =>
	request({
		url: url[url.length - 1] === "/" ? url : url + "/",
		method,
		data,
	})

export const parseParams = (params) => {
	const keys = Object.keys(params)
	let options = ""

	keys.forEach((key) => {
		// console.log(key, params[key], typeof params[key] === "object")
		const isParamTypeObject = typeof params[key] === "object"
		const isParamTypeArray = isParamTypeObject && params[key].length >= 0

		if (!isParamTypeObject && params[key] !== undefined) {
			options += `${key}=${params[key]}&`
		}

		if (isParamTypeObject && isParamTypeArray) {
			params[key].forEach((element) => {
				options += `${key}=${element}&`
			})
		}
	})

	return options ? options.slice(0, -1) : options
}

export const getHistory = (entity: string) => (id: number) =>
	request({
		url: `/${entity}/${id}/history/`,
		method: "get",
	})

export const post = postOrPut("post")
export const put = postOrPut("put")

export const getList = (baseUrl: string) => (params: any) =>
	request({
		url: `/${baseUrl}/`,
		method: "get",
		params,
		paramsSerializer: (params) => parseParams(params),
	})

export const getDetails = (
	entity: string,
	id: number,
	details: string,
	params: any
) =>
	request({
		url: `/${entity}/${id}/${details}/`,
		method: "get",
		params,
		paramsSerializer: (params) => parseParams(params),
	})

export const create = (entity: string) => (data: any) => post(entity, data)

export const update = (entity: string) => (
	id: number,
	data: any,
	partial = false
) =>
	request({
		url: `/${entity}/${id}/`,
		method: partial ? "patch" : "put",
		data,
	})

export const bulkUpdate = (endpoint: string) => (data: any, partial = false) =>
	request({ url: `/${endpoint}/`, method: partial ? "patch" : "put", data })
