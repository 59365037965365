import { asCurrency, capitalize, formatDate } from "@/utils"
import {
	campaignStatusDisplay,
	FormatType,
	Day,
	Device,
	formatDisplay,
	frequencyCapDurationDisplay,
	Pacing,
	PricingType,
} from "@/select"
import { roleDisplay } from "@/select/user"

export const currency = (n: number | string) => {
	return typeof n === "number" ? asCurrency(n) : n
}

export const isodate = (d?: Date) => (d ? formatDate(d) : "")

export const list = (arr: string[] | null) => {
	return arr && arr.join(", ")
}

export const capitalized_list = (arr: string[] | null) => {
	return arr && arr.map(capitalize).join(", ")
}

export const days = (ds: Day[]) => {
	return ds.map((d) => Day[d]).join(", ")
}

export const number = (n: number) => (isNaN(n) ? 0 : n).toLocaleString()

export const pacing = (p: Pacing) => Pacing[p]

export const percentage = (n: number) =>
	(isNaN(n) ? 0 : n).toLocaleString(undefined, { maximumFractionDigits: 2 }) +
	"%"

export const submitText = (isEdit: boolean) => (isEdit ? "Update" : "Create")

export const truncated = (names: string[]) => {
	const s = names.join(", ").slice(0, 90)
	return s.length === 90 ? s + "..." : s
}

export const bool = (b: boolean) => (b ? "✓" : "✗")
export const campaignStatus = campaignStatusDisplay
export const campaignType = (t) => FormatType[t]
export const device = (d) => Device[d]
export const devices = (ds) => ds.map(device).join(", ")
export const format = formatDisplay
export const formats = (fs: number[]) => fs.map(formatDisplay).join(", ")
export const frequencyCapDuration = frequencyCapDurationDisplay
export const pricingType = (p) => PricingType[p]
export const role = roleDisplay

export const lineDisplay = (l) =>
	`${l.inventory.name} - ${l.context} - ${l.formats
		.map(formatDisplay)
		.join(", ")} - ${l.devices.map((d) => Device[d]).join(", ")}`
