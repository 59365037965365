import {
	APIPaginatedResponse,
	bulkUpdate,
	create,
	get,
	getHistory,
	list,
	lookup,
	update,
} from "./base"
import { CreativeData } from "./types"

const ENDPOINT = "lineitemcreatives"
export const getLineItemCreativeHistory = getHistory(ENDPOINT)
export const getLineItemCreatives = list(ENDPOINT)
export const getLineItemCreative = get(ENDPOINT)

export const createLineItemCreative = create(ENDPOINT)
export const updateLineItemCreative = update(ENDPOINT)
export const updateLineItemCreatives = bulkUpdate(ENDPOINT + "/bulk_update")
