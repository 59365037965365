import { RouteConfig } from "vue-router"
import Layout from "@/layout/index.vue"
import { Role } from "@/select/user"
const userRoutes: RouteConfig = {
	path: "/users",
	component: Layout,
	meta: {
		title: "Users",
		roles: [Role.AgencyAdmin, Role.PublisherAdmin, Role.Admin, Role.TeamAdmin],
	},
	children: [
		{
			path: "",
			component: () =>
				import(/* webpackChunkName: "userlist" */ "@/views/users/index.vue"),
			meta: {
				title: "Users",
				breadcrumb: false,
				icon: "user",
			},
		},
		{
			path: "create",
			component: () =>
				import(/* webpackChunkName: "userdetail" */ "@/views/users/detail.vue"),
			meta: {
				title: "Create User",
				hidden: true,
			},
		},
		{
			path: "edit/:id(\\d+)",
			component: () =>
				import(/* webpackChunkName: "userdetail" */ "@/views/users/detail.vue"),
			meta: {
				title: "Edit User",
				hidden: true,
			},
		},
	],
}

export default userRoutes
