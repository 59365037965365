import {
	VuexModule,
	Module,
	Action,
	Mutation,
	getModule,
} from "vuex-module-decorators"
import { Message } from "element-ui"
import { login, getUserInfo } from "@/api/users"
import { getToken, setToken, removeToken } from "@/utils/cookies"
import store from "@/store"

export interface IUserState {
	token: string
	name: string
	roles: string[]
	show_viewability: boolean
}

@Module({ dynamic: true, store, name: "user" })
class User extends VuexModule implements IUserState {
	public token = getToken() || ""
	public name = ""
	public roles: string[] = []
	show_viewability: boolean = true

	@Mutation
	private SET_TOKEN(token: string) {
		this.token = token
	}

	@Mutation
	private SET_NAME(name: string) {
		this.name = name
	}

	@Mutation
	private SET_ROLES(roles: string[]) {
		this.roles = roles
	}

	@Mutation
	private SET_SHOW_VIEWABILITY(show_viewability: boolean) {
		this.show_viewability = show_viewability
	}

	@Action
	public async Login(userInfo: { username: string; password: string }) {
		let { username } = userInfo
		const { password } = userInfo
		username = username.trim()
		try {
			const { data } = await login({ username, password })
			setToken(data.access)
			this.SET_TOKEN(data.access)
		} catch (err) {
			Message.error(
				err?.response?.data?.detail || "Unknown error. please contact support."
			)
		}
	}

	@Action
	public ResetToken() {
		removeToken()
		this.SET_TOKEN("")
		this.SET_ROLES([])
	}

	@Action
	public async GetUserInfo() {
		if (this.token === "") {
			throw Error("GetUserInfo: token is undefined!")
		}
		const { data } = await getUserInfo({})
		if (!data) {
			throw Error("Verification failed, please Login again.")
		}
		const { roles, name, show_viewability } = data

		// roles must be a non-empty array
		if (!roles || roles.length <= 0) {
			throw Error("GetUserInfo: roles must be a non-null array!")
		}
		this.SET_ROLES(roles)
		this.SET_NAME(name)
		this.SET_SHOW_VIEWABILITY(show_viewability)
	}

	@Action
	public async LogOut() {
		if (this.token === "") {
			throw Error("LogOut: token is undefined!")
		}
		removeToken()
		this.SET_TOKEN("")
		this.SET_ROLES([])
	}

	get isAdmin() {
		return this.roles.includes("admin")
	}
}

export const UserModule = getModule(User)
