/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'brand': {
    width: 16,
    height: 16,
    viewBox: '1 -1 100 125',
    data: '<path pid="0" d="M90 57.7c0 2.7-1.1 5.3-3 7.2L66.9 85c-4 4-10.5 4-14.5 0L15 47.6c-2.2-2.2-3.2-5.2-3-8.2L13.8 21c.5-4.9 4.3-8.7 9.2-9.2L41.3 10c3-.3 6.1.8 8.2 3L87 50.4c1.9 1.9 3 4.6 3 7.3zM14.3 40.3c0 2.1.8 4.1 2.3 5.6L54 83.4c3.1 3.1 8.2 3.1 11.3 0l20.1-20.1c3.1-3.1 3.1-8.2 0-11.3L48 14.6c-1.7-1.7-4-2.5-6.4-2.3l-18.3 1.8c-3.8.4-6.8 3.4-7.2 7.2l-1.8 18.3v.7z"/><path pid="1" d="M45.5 35c0 4.7-3.8 8.4-8.5 8.4s-8.4-3.8-8.4-8.5 3.8-8.4 8.5-8.4c2.2 0 4.4.9 5.9 2.5 1.6 1.6 2.5 3.8 2.5 6zm-14.6 0c0 3.4 2.8 6.2 6.2 6.2s6.2-2.8 6.2-6.2c0-1.6-.6-3.2-1.8-4.4-2.4-2.4-6.3-2.4-8.7 0-1.3 1.2-1.9 2.8-1.9 4.4zm11.4-5.1z"/><text x="1" y="114" font-size="5" font-weight="bold" font-family="\'Helvetica Neue\', Helvetica, Arial-Unicode, Arial, Sans-serif">Created by Logan</text><text x="1" y="119" font-size="5" font-weight="bold" font-family="\'Helvetica Neue\', Helvetica, Arial-Unicode, Arial, Sans-serif">from the Noun Project</text>'
  }
})
