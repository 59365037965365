import {
	APIPaginatedResponse,
	create,
	get,
	getHistory,
	list,
	lookup,
	put,
	update,
} from "./base"
import { CreativeData } from "./types"

const ENDPOINT = "creatives"
export const getCreativeHistory = getHistory(ENDPOINT)
export const getCreatives = list(ENDPOINT)
export const getCreative = get(ENDPOINT)

export const createCreative = create(ENDPOINT)
export const updateCreative = update(ENDPOINT)
export const getMediaFile = (id) => list(`${ENDPOINT}/${id}/media_file`)({})

export const setCreativeActive = (id: number, is_active: boolean) =>
	put(`${ENDPOINT}/${id}/set_active`, { is_active })
