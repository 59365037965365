import { APIPaginatedResponse, create, get, list, lookup, update } from "./base"
import { PublisherData } from "./types"

const ENDPOINT = "publishers"
export const getPublishers: APIPaginatedResponse<PublisherData> = list(ENDPOINT)
export const getPublisher = get(ENDPOINT)
export const lookupPublishers = lookup(ENDPOINT)

export const createPublisher = create(ENDPOINT)
export const updatePublisher = update(ENDPOINT)
