import { APIPaginatedResponse, create, list, lookup, put, update } from "./base"
import request from "@/utils/request"

export enum Type {
	Fixed = 1,
	Percentage,
}

export const getPricingModels = (params: any) =>
	request({
		url: "/pricingmodels/",
		method: "get",
		params,
	})

export const lookupPricingModels = lookup("pricingmodels")
