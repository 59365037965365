import { format } from "date-fns"
import { setHours, setMinutes, setSeconds, setMilliseconds } from "date-fns/fp"

import { Error, ErrorCode } from "./constants"

interface GeneralError {
	detail?: string
	length?: number
}

export function arraysEqual(a: any[], b: any[]) {
	if (!Array.isArray(a) || !Array.isArray(b)) return false
	if (a === b) return true
	if (a.length != b.length) return false
	for (let i = 0; i < a.length; i++) {
		if (a[i] !== b[i]) return false
	}
	return true
}

export function asCurrency(n: number, precision = 2) {
	return (isNaN(n) ? 0 : n).toLocaleString(undefined, {
		style: "currency",
		currency: "EUR",
		maximumFractionDigits: precision,
	})
}

export function asNumber(n?: number): string {
	if (n === undefined) return ""
	return n.toLocaleString()
}

export function asPercentage(n?: number, precision = 2) {
	if (n === undefined) return ""
	return n.toLocaleString(undefined, { maximumFractionDigits: precision }) + "%"
}

export function capitalize(s: string) {
	return s.charAt(0).toUpperCase() + s.slice(1).toLowerCase()
}

export function errorMessage(message: Error, code: ErrorCode) {
	return `${Error[message]} (code: ${ErrorCode[code]})`
}

export function handleErrorResponse(err: any, h?: any): string {
	if (err.response) {
		console.log(err.response.data)
		if (err.response.data.detail) return err.response.data.detail
		if (err.response.data.length)
			return h(
				"div",
				null,
				err.response.data.map((o) =>
					h(
						"p",
						null,
						Object.entries(o).map(([field, messages]) =>
							h(
								"ul",
								null,
								[h("strong", null, field)].concat(
									(messages as string[]).map((m) => h("li", null, m))
								)
							)
						)
					)
				)
			)
		// if (err.response.data.length) return err.response.data.map((o) => {
		//   Object.entries(o).map((f, messages) => ({
		//   })
		// })
	} else if (err.request) {
		return "Server Error"
	} else {
		return err.message
	}
	return "unreachable"
}

export function formatDate(d: Date | null): string {
	if (!d) return ""
	return format(d, "yyyy-MM-dd")
}

export function range(start, end) {
	return Array.from(Array(end + 1 - start).keys()).map((i) => i + start)
}

export function roundFloat(f: number, places: number) {
	return Math.round(f * Math.pow(10, places)) / Math.pow(10, places)
}

export const zeroTime = (d: Date): Date =>
	setHours(0, setMinutes(0, setSeconds(0, setMilliseconds(0, d))))

export function getErrorMessage(err, object) {
	const data =
		err && err.response && err.response.data && err.response.data.name
	if (data) return `Validation error: ${data[0]}`
	return `Error saving ${object}. Please contact support.`
}

export function shallowEqual(object1, object2, compareLength = true) {
	console.log("Comparing: ", object1, object2)
	const keys1 = Object.keys(object1)
	const keys2 = Object.keys(object2)

	if (compareLength) {
		if (keys1.length !== keys2.length) {
			return false
		}
	}

	for (const key of keys1) {
		if (object1[key] !== object2[key]) {
			return false
		}
	}

	return true
}

export function formatCell(f) {
	return (_1, _2, cell, _4) => f(cell)
}

export function formatBoolean(value) {
	return value ? "✔" : "✘"
}

export function queryToList(q: string | (string | null)[]): string[] {
	if (typeof q === "string") {
		return [q]
	}
	if (Array.isArray(q)) {
		return q.filter((v): v is string => v !== null)
	}
	return []
}
